<template>
  <v-container class="mt-12">
    <!-- <div v-if="authState === 'signedin' && user">
      <div>Hello: {{ user.attributes.email }}</div>
    </div> -->
    <amplify-authenticator>
      <amplify-sign-in
        header-text="Administrator Access"
        slot="sign-in"
        submit-button-text="SIGN IN"
        username-alias="email"
        :hide-sign-up="true"
      ></amplify-sign-in>

      <!-- <amplify-sign-up
        slot="sign-up"
        username-alias="email"
        :formFields.prop="formFields"
        :handleSignUp.prop="onHandleSignUp"
      ></amplify-sign-up> -->

      <!-- <amplify-sign-out></amplify-sign-out> -->
    </amplify-authenticator>
  </v-container>
</template>
<script>
// import { Auth } from "aws-amplify";
import { onAuthUIStateChange } from "@aws-amplify/ui-components";

export default {
  name: "Auth",
  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
    });
  },
  data() {
    return {
      user: undefined,
      authState: undefined,
      unsubscribeAuth: undefined,
      formFields: [{ type: "email" }, { type: "password" }],
    };
  },
  beforeDestroy() {
    this.unsubscribeAuth();
  },
  methods: {
    // onHandleSignUp(formData) {
    //   console.log("eeee", formData);
    //   const param = {
    //     ...formData,
    //     attributes: {
    //       ...formData.attributes,
    //     },
    //   };
    //   return Auth.signUp(param);
    //   // Auth.signUp({
    //   //   username,
    //   //   password,
    //   //   attributes: {
    //   //     email, // optional
    //   //     phone_number // optional - E.164 number convention
    //   //     // Other custom attributes...
    //   //   },
    //   //   validationData: [] // optional
    //   // })
    //   //   .then(data => console.log(data))
    //   //   .catch(err => console.log(err));
    // },
  },
};
</script>
